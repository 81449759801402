import { useEffect, useState } from 'react';
import Link from 'next/link';
import AnchorLikeButton from '@/components/Header/anchorLikeButton';
import { useMedia } from '@/hooks/useMedia';
import classNames from 'classnames';
import Spacer from '@/components/FirstView/spacer';
import CompanyLogo from '@/components/Header/companyLogo';
import { Link as Scroll } from 'react-scroll';
import { useUrlContext } from '@/provider/urlContext';
import { useRouter } from 'next/router';
import { recordOnClickSignIn, recordOnClickSignUp } from '@/modules/log';

function ScrollOrLink({
    href,
    query,
    isScroll,
    className,
    children,
    isToColumnIndexPage,
    isToNewsDetailPage,
    isToProjectPage
}: {
    href: string;
    query?: string;
    isScroll: boolean;
    className: string;
    children: string;
    isToColumnIndexPage: boolean;
    isToNewsDetailPage: boolean;
    isToProjectPage: boolean;
}) {
    if (isScroll) {
        if (href === 'project') {
            return (
                <Link href={`/${href}${query}`} passHref className={className}>
                    {children}
                </Link>
            );
        } else {
            return (
                <Scroll to={href} smooth={true} className={className}>
                    {children}
                </Scroll>
            );
        }
    } else {
        if (
            (isToColumnIndexPage || isToNewsDetailPage || isToProjectPage) &&
            (href === 'columns' || href === 'news' || href === 'project')
        ) {
            return (
                <Link href={`/${href}${query}`} passHref className={className}>
                    {children}
                </Link>
            );
        } else {
            return (
                <Link href={`/${query}#${href}`} passHref className={className}>
                    {children}
                </Link>
            );
        }
    }
}

const Header = () => {
    const [clicked, setClicked] = useState<boolean>(false);
    const isPC = useMedia();
    const router = useRouter();

    const domain = process.env.NEXT_PUBLIC_PRO_CONNECT_APP_FL_DOMAIN;
    const { signUpUrl, signInUrl, query } = useUrlContext();
    const signInUrlWithDomain = `https://${domain}${signInUrl}`;
    const signUpUrlWithDomain = `https://${domain}${signUpUrl}`;
    const isTopPage = router.pathname === '/';
    const isColumnDetailPage = router.pathname.includes('columns');
    const isNewsDetailPage = router.pathname.includes('news');
    const isProjectPage = router.pathname.includes('project');
    const links: { name: string; href: string; query: string }[] = [];

    useEffect(() => {
        const element = document.querySelector('.foldable-menu');
        // 要素の透明度が0なら要素を非表示に, 100なら表示にする
        const getVisibilityClassNames = (className: string) => {
            return classNames(
                className,
                { invisible: /opacity-0/.test(className) },
                { visible: /opacity-100/.test(className) }
            );
        };

        if (element) {
            // ページ表示時とアニメーション終了時に表示を制御する
            element.className = getVisibilityClassNames(element.className);
            element.addEventListener('transitionend', () => {
                element.className = getVisibilityClassNames(element.className);
            });
        }
    }, []);

    return (
        <header className="h-14 md:h-16 flex justify-between px-2 md:px-3 lg:px-4 border-b border-lightGrey w-full">
            <div className="my-auto">
                <Link href={`/${query}`}>
                    <CompanyLogo className="w-24 h-7 lg:w-45 lg:h-9 xl:w-56 xl:h-10" />
                </Link>
            </div>
            {isPC ? (
                <div className="flex">
                    {links.map(({ name, href, query }, i) => (
                        <ScrollOrLink
                            key={i}
                            href={href}
                            query={query}
                            isScroll={isTopPage}
                            isToColumnIndexPage={isColumnDetailPage}
                            isToNewsDetailPage={isNewsDetailPage}
                            isToProjectPage={isProjectPage}
                            className="tracking-tighter lg:tracking-normal text-[12px] xl:text-[14px] leading-[64px] font-medium text-primary mr-1 md:mr-[6px] lg:mr-[7px] xl:mr-4 hover:opacity-70 cursor-pointer"
                        >
                            {name}
                        </ScrollOrLink>
                    ))}
                    <Link
                        href={`/corp${query}`}
                        passHref
                        className="tracking-tighter lg:tracking-normal text-[12px] xl:text-[14px] font-medium text-primary mr-1 md:mr-[6px] lg:mr-3 xl:mr-4 hover:opacity-70 cursor-pointer flex items-center leading-[16px]"
                    >
                        人材をお探しの
                        <br />
                        企業様はこちら
                    </Link>
                    <AnchorLikeButton
                        href={signInUrlWithDomain}
                        onClick={() => recordOnClickSignIn()}
                        className="border-[#1D55AE] rounded-[60px] border-2 border-solid w-[129px] h-[40px] leading-[40px] my-auto mr-[16px] text-[#1D55AE] flex items-center justify-center"
                    >
                        ログイン
                    </AnchorLikeButton>
                    <AnchorLikeButton
                        href={signUpUrlWithDomain}
                        onClick={() => recordOnClickSignUp()}
                        className="rounded-[60px] w-[129px] h-[40px] leading-[40px] my-auto bg-[#CC9900] text-white"
                    >
                        無料会員登録
                    </AnchorLikeButton>
                </div>
            ) : (
                <span
                    className="relative w-8 h-5 my-auto"
                    onClick={() => setClicked((oldValue) => !oldValue)}
                >
                    {[...Array(3)].map((_, i) => (
                        <i
                            key={i}
                            className={classNames(
                                'transition-all w-full h-0.5 bg-primary absolute duration-500',
                                { 'top-0': i === 0 },
                                {
                                    'translate-y-3 rotate-[-45deg]':
                                        i === 0 && clicked
                                },
                                { 'top-0 bottom-0 m-auto': i === 1 },
                                { 'opacity-0': i === 1 && clicked },
                                { 'bottom-0': i === 2 },
                                {
                                    'translate-y-[-6px] rotate-45':
                                        i === 2 && clicked
                                }
                            )}
                        ></i>
                    ))}
                </span>
            )}
            {!isPC && (
                <div
                    className={classNames(
                        'foldable-menu',
                        'absolute w-full top-14 md:top-14 z-[999] left-0 p-4 bg-white transition-all duration-500',
                        { 'opacity-0 invisible': !clicked },
                        { 'opacity-100 visible': clicked }
                    )}
                >
                    {links.map((link, i) => (
                        <div key={i}>
                            <Spacer size={3} />
                            <ScrollOrLink
                                href={link.href}
                                query={link.query}
                                isScroll={isTopPage}
                                isToColumnIndexPage={isColumnDetailPage}
                                isToNewsDetailPage={isNewsDetailPage}
                                isToProjectPage={isProjectPage}
                                className="color-primary text-base text-center block font-medium"
                            >
                                {link.name}
                            </ScrollOrLink>
                            <Spacer
                                size={3}
                                className="border-b border-lightGrey"
                            />
                        </div>
                    ))}
                    <Spacer size={4} />
                    <AnchorLikeButton
                        href={signUpUrlWithDomain}
                        onClick={() => recordOnClickSignUp()}
                        className="rounded-[60px] max-w-[311px] w-full h-[48px] leading-[48px] my-auto bg-[#CC9900] text-white block text-[16px] mx-auto"
                    >
                        無料会員登録
                    </AnchorLikeButton>
                    <Spacer size={4} />
                    <AnchorLikeButton
                        href={signInUrlWithDomain}
                        onClick={() => recordOnClickSignIn()}
                        className="border-[#1D55AE] max-w-[311px] w-full rounded-[60px] border-2 border-solid h-[48px] leading-[48px] my-auto block text-[16px] mx-auto text-[#1D55AE] flex items-center justify-center"
                    >
                        ログイン
                    </AnchorLikeButton>
                    <Spacer size={4} />
                    <AnchorLikeButton
                        href={`/corp${query}`}
                        className="h-auto leading-5 my-auto px-4 py-2 bg-white block mx-auto text-sm font-bold text-primary border-2 border-primary max-w-[311px] w-full"
                        useNextLink
                    >
                        コンサルタント・エンジニアを
                        <br />
                        お探しの企業様はこちら
                    </AnchorLikeButton>
                </div>
            )}
        </header>
    );
};

export default Header;
