import Image from 'next/image';

export function IconSearchWhite() {
    return (
        <Image
            src={'/images/icon-search-white.webp'}
            alt="Icon"
            width={15}
            height={15}
            priority
            loading="eager"
        />
    );
}
