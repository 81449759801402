import Image from 'next/image';
import AnchorLikeButton from '@/components/Header/anchorLikeButton';
import { useUrlContext } from '@/provider/urlContext';
import { recordOnClickSignUp } from '@/modules/log';
import { ProjectSearch } from '@/components/ShortLP/FirstView/ProjectSearch';
import { ProjectsFilterForm, ProjectsFilterFormForFV } from '@/types/project';
import { useForm } from '@mantine/form';
import { initialProjectSearchValues } from '@/modules/config/project';
import { Master } from '@/types/master';
import { useRouter } from 'next/router';
import { useMedia } from '@/hooks/useMedia';
import { othersAtOfficeFrequency } from '@/constants/master';

const imagePCBackgroundFv = '/images/short-lp/pc/lp-top-background.webp';

const imagePCHumanFv = '/images/short-lp/pc/lp-top-human.webp';

const imageSPBackgroundFv = '/images/short-lp/sp/lp-top-background-sp.webp';

const imageSPHumanFv = '/images/short-lp/sp/lp-top-human-sp.webp';

export type FirstViewProps = {
    atOfficeFrequencies: Master[];
};

function FirstView({ atOfficeFrequencies }: FirstViewProps) {
    const router = useRouter();
    const isPC = useMedia();

    const domain = process.env.NEXT_PUBLIC_PRO_CONNECT_APP_FL_DOMAIN;
    const { signUpUrl, query } = useUrlContext();
    const signUpUrlWithDomain = `https://${domain}${signUpUrl}`;

    const filteredAtOfficeFrequencies = atOfficeFrequencies.filter(
        (atOfficeFrequency) =>
            atOfficeFrequency.id !== othersAtOfficeFrequency.id
    );

    const { values, setFieldValue } = useForm<ProjectsFilterFormForFV>({
        initialValues: {
            text: initialProjectSearchValues.text,
            atOfficeFrequency: initialProjectSearchValues.atOfficeFrequency,
            lowerLimitMonthlyRate:
                initialProjectSearchValues.lowerLimitMonthlyRate
        }
    });

    const handleOnChange = (
        field: keyof ProjectsFilterForm,
        value: string | number | string[]
    ) => {
        setFieldValue(field, value);
    };

    const onFilter = () => {
        const convertedQueryValues = {
            ...router.query,
            ...values,
            lowerLimitMonthlyRate:
                values.lowerLimitMonthlyRate?.toString() || '',
            page: '1',
            orderBy: initialProjectSearchValues.orderBy
        };

        const searchParams = new URLSearchParams(convertedQueryValues);
        router.push({
            pathname: 'project/search',
            query: searchParams.toString()
        });
    };

    return (
        <>
            <div className="flex flex-col md:flex-row h-full">
                <div className="relative w-full">
                    <Image
                        src={imagePCBackgroundFv}
                        alt=""
                        fill
                        className="md:block hidden object-[right_calc(20%)_top_calc(0%)] z-[-10] object-cover"
                    />
                    <Image
                        src={imagePCHumanFv}
                        alt=""
                        fill
                        className="md:block hidden object-[right_calc(20%)_top_calc(0%)] z-[-1] object-cover md:max-w-[440px] lg:max-w-[550px] md:ml-auto lg:mr-20"
                    />
                    <Image
                        src={imageSPBackgroundFv}
                        alt=""
                        fill
                        className="md:hidden block object-[right_calc(20%)_top_calc(0%)] z-[-10] object-cover"
                    />
                    <Image
                        src={imageSPHumanFv}
                        alt=""
                        fill
                        className="md:hidden block object-[right_calc(20%)_top_calc(0%)] z-[-1] object-cover md:max-w-[440px] lg:max-w-[550px] md:ml-auto"
                    />
                    <div className="py-[20px] md:py-[60px] px-[12px] lg:px-[70px] xl:px-[120px] mt-62 md:mt-0">
                        <p className="font-hiragino-sans text-white text-[28px] sm:text-[38px] md:text-[36px] xl:text-[60px] leading-[60px] sm:leading-[70px] md:leading-[80px] xl:leading-[90px] md:mb-[40px] mx-[16px]">
                            <span className="md:block">
                                フリーランス
                                <br />
                                コンサルタントのための
                                <br />
                                案件紹介サービス
                            </span>
                        </p>
                        <div className="flex md:flex-row flex-col items-center sm:justify-start justify-center mt-[10px] md:m-0">
                            <AnchorLikeButton
                                href={signUpUrlWithDomain}
                                onClick={() => recordOnClickSignUp()}
                                className="flex items-center justify-center rounded-[4px] max-w-[366px] w-full h-[64px] leading-[26px] bg-[#000033]"
                            >
                                <p className="font-hiragino-sans text-white text-center md:text-[20px] text-[18px] mb-[2px]">
                                    <span className="text-[15px]">
                                        非公開案件多数！
                                    </span>
                                    <br />
                                    登録して案件紹介を受ける
                                </p>
                            </AnchorLikeButton>
                            <AnchorLikeButton
                                href={`/project${query}`}
                                className="flex items-center justify-center rounded-[4px] max-w-[366px] w-full h-[64px] leading-[26px] bg-[#000033] border border-solid border-[#000033] md:ml-[20px] mt-[12px] md:mt-0 "
                            >
                                <p className="font-hiragino-sans text-white text-center text-[20px]">
                                    案件一覧
                                </p>
                            </AnchorLikeButton>
                        </div>
                    </div>
                </div>
            </div>
            {!isPC && (
                <ProjectSearch
                    values={values}
                    atOfficeFrequencies={filteredAtOfficeFrequencies}
                    onChangeValue={handleOnChange}
                    onFilter={onFilter}
                />
            )}
            {isPC && (
                <div className="w-full content-between">
                    <ProjectSearch
                        values={values}
                        atOfficeFrequencies={filteredAtOfficeFrequencies}
                        onChangeValue={handleOnChange}
                        onFilter={onFilter}
                    />
                </div>
            )}
        </>
    );
}

export default FirstView;
